import React from 'react';

import { PageHeader, Button } from 'antd';
import {
  useHistory
} from "react-router-dom";
import { RollbackOutlined } from '@ant-design/icons';
import firebase from "firebase/app";
import "firebase/auth";


export default function PrimarySearchAppBar({ newWorkflow }) {
  const history = useHistory();
  function signOutUser() {
    firebase.auth().signOut().then(() => {
      history.push('/account/login')
    })
  }

  function gotoUpgrade(){
    history.push('/user/upgrade')
  }

  return (
    <div className="bg-white">
      <PageHeader
        className="site-page-header-responsive"
        // onBack={() => window.history.back()}
        title={<span className="text-2xl font-bold">MindMirror.co</span>}
        // subTitle={<p>Hello</p>}
        extra={[
          <Button style={{ backgroundColor: '#549bd6', paddingLeft: 30, paddingRight: 30, height: 35, fontWeight: 'bold' }} onClick={() => newWorkflow()} key="1" type="primary">
            New Board
          </Button>,
          <Button onClick={() => gotoUpgrade()} type="dashed" key="3">Upgrade</Button>,
          <Button style={{ color: 'red' }} icon={<RollbackOutlined />} className="ant-dropdown-link" onClick={() => signOutUser()} key="2">Sign Out</Button>
        ]}
      >

      </PageHeader>
    </div>
  );
}