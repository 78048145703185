import React, { useState } from 'react';
import {
    useHistory
} from "react-router-dom";
import { Card, Button, message } from 'antd';
import TextField from '@material-ui/core/TextField';
import Lottie from 'react-lottie';
import animationData from '../lotties/passwordReset.json';
import firebase from "firebase/app";
import "firebase/auth";

export default function ResetFunction() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const history = useHistory();
    const [email, setEmail] = useState(null);
    const [loading, toggleLoading] = useState(false);

    function sendPassWordReset() {
        if (!email) {
            message.error('Please enter a valid email')
            return
        }
        toggleLoading(true)
        firebase.auth().sendPasswordResetEmail(email).then(() => {
            message.success('Your password reset email has been sent')
            toggleLoading(false)
        }).catch((error) => {
            toggleLoading(false)
            message.error(error.message)
        })
    }

    function gotoLogin() {
        history.push('/account/login')
    }
    return (
        <div className="flex flex-col h-screen bg-purple">
            <div className="mx-auto mt-12">
                <div className="mb-10 hidden lg:block">
                    <Lottie
                        options={defaultOptions}
                        height={200}
                        width={200}
                    />
                </div>
                <Card style={{ borderRadius: 10 }}>
                    <div className="flex flex-col">
                        <h1 className="text-xl">Reset your password</h1>
                        <TextField
                            id="reset-email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            style={{ margin: 10 }}
                            className="w-60"
                            label="Email"
                            helperText="Enter email you registered with"
                            variant="outlined"
                        />
                        <Button className="font-bold" style={{ height: 40, backgroundColor: '#549bd6', fontWeight: 'bold' }} onClick={() => sendPassWordReset()} variant="contained" type="primary">
                            {!loading ? 'Send email' : <span className="text-white" >Please wait...</span>}
                        </Button>
                        <h3 onClick={() => gotoLogin()} className="underline mt-5 text-center grab">Back to login</h3>
                    </div>
                </Card>
            </div>
        </div>
    )
}
