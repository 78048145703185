import React, { useState } from 'react';
import { Modal } from 'antd';

export default function VideoDisplay({ srcBlob, closeModal }) {
    // const [loading, toggleLoading] = useState(false)
    const [visible, toggleVisible] = useState(true)

    // const showModal = () => {
    //     toggleVisible(true)
    // };

    const handleOk = () => {
        // toggleLoading(true)
        setTimeout(() => {
            // toggleLoading(false)
            toggleVisible(false)
        }, 3000);
    };

    const handleCancel = () => {
        closeModal()
        toggleVisible(false)
    };

    return (
        <>
            <Modal
                visible={visible}
                title="Video preview"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                ]}
            >
                <video
                    src={URL.createObjectURL(srcBlob)}
                    autoPlay={true}
                    width={520}
                    height={480}
                    controls
                />
            </Modal>
        </>
    );

}