import React from 'react';
import { Input } from 'antd'
import {
  useHistory
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setLoadedFlowAction } from '../store/general';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ShareModal({ isOpen, closeModal }) {
  const dispatch = useDispatch()
  const history = useHistory();
  const [title, setTitle] = React.useState(null)

  const handleClickCreate = () => {
    if (title?.length > 0) {
      dispatch(setLoadedFlowAction([]))
      history.push(`/editor/new?id=${title}`);
      closeModal()
    }
  };

  const handleClose = () => {
    closeModal()
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Share access"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div class="w-96">
              <p>Enter email addresses</p>
              <Input style={{ width: '100%' }} placeholder="Seperate with commas" value={title} onChange={(e) => setTitle(e.target.value)} />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickCreate} color="primary" autoFocus>
            Share
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}