import React, { useState, useEffect } from 'react';
import { Button, Input, message } from 'antd';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

export default function Settings() {
    const [fullName, setFullName] = useState(null)
    const [passWord, setPassword] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [shouldShowPassword, setShouldShowPassword] = useState(false)

    useEffect(() => {
        firebase.database().ref(`users/${firebase.auth().currentUser.uid}`).on("value", (dataSnapShot) => {
            setFullName(dataSnapShot.val().name)
            const type = dataSnapShot.val().type
            if (type === 'google' || type === 'facebook') {
                setShouldShowPassword(false)
            }
            else {
                setShouldShowPassword(true)
            }
        })
    }, [])

    function saveSettings() {
        firebase.database().ref(`users/${firebase.auth().currentUser.uid}`).update({
            name: fullName
        }).then(() => {
            message.success('Update successful')
        })
    }

    if (!shouldShowPassword) {
        return (
            <div className="mx-10">
                <div className="mt-5 py-10">
                    <div className="flex flex-row space-between">
                        <h1 className="text-2xl font-bold">User settings</h1>
                        <Button onClick={() => saveSettings()} style={{ marginLeft: 'auto', marginRight: 10 }} type="primary">Save Changes</Button>
                    </div>
                    <p>Any changes you make will apply to your Mind Mirror accounts.</p>
                </div>
                <hr className="mb-5" />
                <div className="w-56 mb-5">
                    <p className="font-bold text-1xl">Profile Settings</p>
                    <p>User profiles can for Google and Facebook accounts cannot be edited here</p>
                </div>
            </div>
        )
    }
    return (
        <div className="mx-10">
            <div className="mt-5 py-10">
                <div className="flex flex-row space-between">
                    <h1 className="text-2xl font-bold">User settings</h1>
                    <Button onClick={() => saveSettings()} style={{ marginLeft: 'auto', marginRight: 10 }} type="primary">Save Changes</Button>
                </div>
                <p>Any changes you make will apply to your Mind Mirror accounts.</p>
            </div>
            <hr className="mb-5" />
            <div className="w-56 mb-5">
                <p className="font-bold text-1xl">Profile Settings</p>
                <div className="lg:grid lg:grid-flow-row lg:grid-cols-2 lg:grid-rows-1 lg:gap-2">
                    <p className="mt-2 mr-8">Full name</p>
                    <Input value={fullName} onChange={(e) => setFullName(e.target.value)} style={{ width: 300, height: 40 }} placeholder="Type full name here" />
                </div>
            </div>
            <hr className="my-5" />
            <p className="font-bold text-1xl">Change password</p>
            {
                !shouldShowPassword && <p>Change password disabled for Google and Facebook logins</p>
            }
            {
                shouldShowPassword && <>
                    <div className="w-56">
                        <div className="lg:grid lg:grid-flow-row lg:grid-cols-2 lg:grid-rows-1 lg:gap-2">
                            <p className="mt-1 mr-8">Current password</p>
                            <Input value={passWord} onChange={(e) => setPassword(e.target.value)} style={{ width: 300, height: 40 }} placeholder="Type current password here" />
                        </div>
                        <div className="lg:grid lg:grid-flow-row lg:grid-cols-2 lg:grid-rows-1 lg:gap-2">
                            <p className="mt-2 mr-8">New password</p>
                            <Input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} style={{ width: 300, height: 40 }} placeholder="Type new password here" />
                        </div>
                        <div className="lg:grid lg:grid-flow-row lg:grid-cols-2 lg:grid-rows-1 lg:gap-2 mb-5">
                            <p className="mt-2 mr-8">Confirm</p>
                            <Input value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} style={{ width: 300, height: 40 }} placeholder="Type new password here again" />
                        </div>
                        <Button onClick={() => saveSettings()} style={{ marginLeft: 'auto', marginRight: 10 }} type="primary">Change password</Button>
                    </div>
                </>
            }
        </div>
    )
}