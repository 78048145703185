import { createSlice } from '@reduxjs/toolkit';
const initialUser = localStorage.getItem('user')
? JSON.parse(localStorage.getItem('user'))
: null

const slice = createSlice({
  name: 'user',
  initialState: {
    user: initialUser,
  },
  reducers: {
    loginSuccess: (state, action) => {
        localStorage.setItem('user', JSON.stringify(action.payload))
      // state.user = action.payload;
    },
    logoutSuccess: (state, action) =>  {
        localStorage.removeItem('user')
      // state.user = null;
    },
  },
});
export default slice.reducer
// Actions
const { loginSuccess, logoutSuccess } = slice.actions
export const login = ({ username, password }) => async dispatch => {
  try {
    // const res = await api.post('/api/auth/login/', { username, password })
    dispatch(loginSuccess({username}));
  } catch (e) {
    return console.error(e.message);
  }
}
export const logout = () => async dispatch => {
  try {
    // const res = await api.post('/api/auth/logout/')
    return dispatch(logoutSuccess())
  } catch (e) {
    return console.error(e.message);
  }
}