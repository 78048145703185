import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBp8ZTrsjhhByj9SG4gb78unuwwkl_ZY4M",
    authDomain: "mind-mirror-admin.firebaseapp.com",
    databaseURL: "https://mind-mirror-admin-default-rtdb.firebaseio.com",
    projectId: "mind-mirror-admin",
    storageBucket: "mind-mirror-admin.appspot.com",
    messagingSenderId: "4950751488",
    appId: "1:4950751488:web:e0a0ce4f0b721161f11be5",
    measurementId: "G-Q65WPJ4M4R"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firebaseApp = firebase.app();


// {
//     const firebaseConfig = {
//         apiKey: "AIzaSyC_qeaxUItBldqYegCH4shsvoGrp1Mt7D0",
//         authDomain: "big-picture-admin.firebaseapp.com",
//         databaseURL: "https://big-picture-admin-default-rtdb.firebaseio.com",
//         projectId: "big-picture-admin",
//         storageBucket: "big-picture-admin.appspot.com",
//         messagingSenderId: "355139610829",
//         appId: "1:355139610829:web:cd658ad3cdcfad0b602184",
//         measurementId: "G-XVNJFZ9C6G"

//         apiKey: "AIzaSyBp8ZTrsjhhByj9SG4gb78unuwwkl_ZY4M",
//         authDomain: "mind-mirror-admin.firebaseapp.com",
//         databaseURL: "https://mind-mirror-admin-default-rtdb.firebaseio.com",
//         projectId: "mind-mirror-admin",
//         storageBucket: "mind-mirror-admin.appspot.com",
//         messagingSenderId: "4950751488",
//         appId: "1:4950751488:web:e0a0ce4f0b721161f11be5",
//         measurementId: "G-Q65WPJ4M4R"
//     };
// }
