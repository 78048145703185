import React, { useEffect } from 'react';
import HeaderComp from '../components/HeaderComp';
import TabsComp from '../components/TabsComp';
import Modal from '../components/Modal';
import ShareModal from '../components/ShareModal';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { useDispatch, useSelector } from 'react-redux';
import { setLoadedFlowAction, openCreateNewAction, shareDocAction, setSubscribedAction } from '../store/general';

export default function Home() {
    const dispatch = useDispatch()
    const general = useSelector((state) => state.general)
    // const [open, toggleOpen] = useState(false);


    useEffect(() => {
        firebase.database().ref(`users/${firebase.auth().currentUser.uid}`).on("value", (dataSnapShot) => {
            let expdate = new Date(dataSnapShot.val().expiry)
            let current = new Date()
            if(expdate < current){
                dispatch(setSubscribedAction(false))
            }
            if(current < expdate){
                dispatch(setSubscribedAction(true))
            }
        })
    }, [dispatch, general])

    function closeModal() {
        // toggleOpen(false);
        dispatch(openCreateNewAction(false));
        dispatch(shareDocAction(false))
    }

    function goToNewEditor() {
        dispatch(setLoadedFlowAction([]))
        dispatch(openCreateNewAction(true));
    }
    return (
        <div className="flex flex-row h-screen">
            <Modal isOpen={general.openCreateModal} closeModal={closeModal} />
            <ShareModal isOpen={general.openShareModal} closeModal={closeModal} />
            <div className="w-full">
                <HeaderComp newWorkflow={goToNewEditor} />
                <hr />
                <div className="m-5">
                    <TabsComp />
                </div>
            </div>
        </div>
    )
}