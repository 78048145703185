import React, { useState } from 'react';
import {
  useHistory
} from "react-router-dom";
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import {
  PlusSquareOutlined,
  UndoOutlined,
  RedoOutlined,
  SaveOutlined,
  ArrowLeftOutlined,
  FundProjectionScreenOutlined,
  StopOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
const { SubMenu } = Menu;


export default function DrawerComp({ addBlock, addText, addImage, undoAction, redoAction, saveAction, setPreview,
  startRecord, startRecordNoVideo, stopRecord }) {
  const history = useHistory();
  const general = useSelector((state) => state.general)
  const [collapsed] = useState(true);


  const previewActionIcon = () => {
    if (general.previewMode === false) {
      return (
        <FundProjectionScreenOutlined />
      )
    }
    else {
      return (
        <StopOutlined />
      )
    }
  }

  /* const recordActionIcon = () => {
    if (general.previewMode === false) {
      return (
        <VideoCameraOutlined />
      )
    }
    else {
      return (
        <StopOutlined />
      )
    }
  } */

  return (
    <div>
      {/* <Button type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
      </Button> */}
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
      >
        <Menu.Item key="1" onClick={() => history.goBack()} icon={<ArrowLeftOutlined />}>
          Go back
        </Menu.Item>
        <SubMenu key="sub1" icon={<PlusSquareOutlined />} title="New item">
          {/* <Menu.Item onClick={() => addBlock()} key="7">Empy Block</Menu.Item> */}
          <Menu.Item onClick={() => addText()} key="8">Text</Menu.Item>
          <Menu.Item onClick={() => addImage()} key="9">Image</Menu.Item>
        </SubMenu>
        <Menu.Item onClick={() => undoAction()} key="3" icon={<UndoOutlined />}>
          Undo
        </Menu.Item>
        <Menu.Item key="4" onClick={() => redoAction()} icon={<RedoOutlined />}>
          Redo
        </Menu.Item>
        <Menu.Item key="5" onClick={() => saveAction()} icon={<SaveOutlined />}>
          Save current state
        </Menu.Item>
        <Menu.Item key="6" onClick={() => setPreview()} icon={previewActionIcon()}>
          {general.previewMode ? ' Stop Preview' : 'Preview'}
        </Menu.Item>
        {
          general.previewMode !== 'Preview' && (
            <SubMenu key="7" icon={<VideoCameraOutlined />} title={general.previewMode ? ' Stop Record' : 'Record'}>
              <Menu.Item onClick={() => startRecord()} key="10">Start Record with video</Menu.Item>
              <Menu.Item onClick={() => startRecordNoVideo()} key="100">Start Record with no video</Menu.Item>
              <Menu.Item onClick={() => stopRecord()} key="11">Stop Record</Menu.Item>
            </SubMenu>
          )
        }
      </Menu>
    </div>
  )
}
