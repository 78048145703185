import React from 'react';
import { Tabs } from 'antd';
import RecentDocuments from './RecentDocuments';
// import Recommended from './Recommended';

const { TabPane } = Tabs;

export default function FullWidthTabs() {
    function callback(key) {
    }

    return (
        <div className="w-full h-screen">
            <Tabs  type="card" style={{ color: '#900cec' }} defaultActiveKey="1" onChange={callback}>
                <TabPane tab={<span className="text-purple font-bold">Recent Documents</span>} key="1">
                    <RecentDocuments />
                </TabPane>
                {/* <TabPane tab={<span className="text-purple">Recommended workflows</span>} key="2">
                    <Recommended />
                </TabPane> */}
            </Tabs>
        </div>
    );
}
