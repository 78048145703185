import React from 'react';
import {
    useHistory
} from "react-router-dom";
import { PaystackConsumer } from 'react-paystack';
import { message } from 'antd';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

export default function Upgrade() {
    const history = useHistory();

    let config = {
        reference: (new Date()).getTime().toString(),
        email: `${firebase.auth().currentUser.email}`,
        amount: 250000,
        publicKey: 'pk_live_4bf8ce9bc2fb71036cec170d5fc4acc5d7e887f3'
        // publicKey: 'pk_test_8a5124342763ff4d7ae0d00f52b99772e0fb40da',
    };

    let config2 = {
        reference: (new Date()).getTime().toString(),
        email: `${firebase.auth().currentUser.email}`,
        amount: 1800000,
        publicKey: 'pk_live_4bf8ce9bc2fb71036cec170d5fc4acc5d7e887f3'
        // publicKey: 'pk_test_8a5124342763ff4d7ae0d00f52b99772e0fb40da',
    };

    // you can call this function anything
    const handleSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        let date = new Date()
        var expiryMonth = new Date(date.setMonth(date.getMonth() + 1));
        firebase.database().ref(`users/${firebase.auth().currentUser.uid}`).update({
            subscription: 'monthly',
            expiry: expiryMonth,
            ref: reference
        }).then(() => {
            message.success('Subscription successfuly. You now have access to all features')
        })
    };

    const handleSuccess2 = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        let date = new Date()
        var expiryMonth = new Date(date.setMonth(date.getMonth() + 12));
        firebase.database().ref(`users/${firebase.auth().currentUser.uid}`).update({
            subscription: 'yearly',
            expiry: expiryMonth,
            ref: reference
        }).then(() => {
            message.success('Subscription successfuly. You now have access to all features')
        })
    };

    // you can call this function anything
    const handleClose = () => {
        config.reference = (new Date()).getTime().toString()
        config2.reference = (new Date()).getTime().toString()
        message.info('You have closed the Secure payment dialog')
    }

    const componentProps = {
        ...config,
        text: 'Paystack Button Implementation',
        onSuccess: (reference) => handleSuccess(reference),
        onClose: handleClose
    };

    const componentProps2 = {
        ...config2,
        text: 'Paystack Button Implementation',
        onSuccess: (reference) => handleSuccess2(reference),
        onClose: handleClose
    };

    // you can call this function anything
    // const onClose = () => {
    //     config.reference = (new Date()).getTime().toString()
    //     config2.reference = (new Date()).getTime().toString()
    //     message.info('Payment not completed')
    // }

    return (
        <div className="flex flex-col bg-purple h-screen w-full">
            <div onClick={() => history.push('/home')} className="absolute right-5 mt-5 grab bg-white rounded-full px-5 border border-2 p-3">
                <span className="font-bold">X</span>
            </div>
            <div className="m-auto bg-white border border-2 rounded-md px-6 py-4">
                <div className="py-10 px-10">
                    <h2 className="text-2xl text-center">Upgrade to unlock more features</h2>
                    <span>Enjoy features like using images in workflows and longer video recordings when you upgrade</span>
                    <div className="lg:grid lg:grid-cols-2 lg:gap-10 mt-10 xs:flex xs:flex-col">
                        <div className="flex flex-col text-center bg-purple border rounded-md">
                            <div className="bg-lightpurple p-4">
                                <span className="text-white font-bold">Monthly</span>
                            </div>
                            <div className="flex flex-col py-5 px-5 my-5">
                                <span className="text-2xl text-white font-bold">$<span className="text-6xl">5</span></span>
                                <span className="text-white my-3">Billed monthly</span>
                                <PaystackConsumer {...componentProps} >
                                    {({ initializePayment }) => <div onClick={() => initializePayment(handleSuccess, handleClose)} className="flex flex-col bg-white p-3 mt-5 border rounded-md grab">
                                        <p className="my-auto">Upgrade</p>
                                    </div>}
                                </PaystackConsumer>
                            </div>
                        </div>
                        <div className="xs:mt-10 lg:mt-0 flex flex-col text-center bg-purple border rounded-md">
                            <div className="bg-white p-4">
                                <span className="font-bold text-purple">Yearly</span>
                            </div>
                            <div className="flex flex-col py-5 px-5 my-5">
                                <span className="text-2xl text-white font-bold">$<span className="text-6xl">36</span></span>
                                <span className="text-white my-3">Billed yearly</span>
                                <PaystackConsumer {...componentProps2} >
                                    {({ initializePayment }) => <div onClick={() => initializePayment(handleSuccess, handleClose)} className="flex flex-col bg-white p-3 mt-5 border rounded-md grab">
                                        <p className="my-auto">Upgrade</p>
                                    </div>}
                                </PaystackConsumer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}