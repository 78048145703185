/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory
} from "react-router-dom";
import { Popover, Button, message } from 'antd';
import VideoDisplay from '../components/VideoDisplay';
import {
  openEditorAction, setCurrentNodeAction, setLoadedFlowAction, setPreviewModeAction,
  setRecordModeAction, showVideoAction, setIsRecordAction
} from '../store/general';
import HTMLRenderer from 'react-html-renderer'
import DrawerComp from '../components/DrawerComp';
import ReactFlow, { ReactFlowProvider, addEdge, Handle, removeElements, Controls, Background, MiniMap } from 'react-flow-renderer';
import SideEditor from '../components/SideEditor';
import useMediaRecorder from '@wmik/use-media-recorder';
import Webcam from "react-webcam";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth"


export default function NewEditor({ location }) {
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const dispatch = useDispatch()
  const general = useSelector((state) => state.general)
  const [rfInstance, setRfInstance] = useState(null);
  const getNodeId = () => `rand_${+new Date().getTime()}`;
  // const onLoad = (reactFlowInstance) => reactFlowInstance.fitView();
  const isValidConnection = (connection) => connection !== null;
  const onConnectStart = (event, { nodeId, handleType }) => console.log('on connect start', { nodeId, handleType });
  const onConnectStop = (event) => {
    //console.log('on connect stop', event);
  }
  const onConnectEnd = (event) => {
    // console.log('on connect end', event);
}
  const onElementsRemove = (elementsToRemove) => setElements((els) => removeElements(elementsToRemove, els));


  const [elements, setElements] = useState(general.loadedFlow);
  const [, setEditElements] = useState(general.editnodes);
  const [undoStack, setUndoStack] = useState([])
  const [videoData,] = useState(null);
  const [recordingMode,] = useState(true)

  let {
    status,
    mediaBlob,
    stopRecording,
    startRecording
  } = useMediaRecorder({
    recordScreen: true,
    blobOptions: { type: 'video/mp4' },
    mediaStreamConstraints: { audio: true, video: recordingMode }
  });

  const videoConstraints = {
    facingMode: "user"
  };


  useEffect(() => {
    // setInterval(() => {
    //   saveAction()
    // }, 50000);
    if (general.previewMode && general.recordScreen) {
      if (status === 'acquiring_media' || status === 'recording') {

      }
      else {
        startRecording()
      }
    }
    function getNodeImageById() {
      const id = general.currentNode.id
      const currentNodeUpdatedImage = general.nodeImages[id] || ''
      return currentNodeUpdatedImage;
    }

    function getCurrentNodeUpdatedText() {
      const id = general.currentNode.id
      const currentNodeUpdatedText = general.nodeTexts[id] || ''
      return currentNodeUpdatedText;
    }

    function getCurrentNodeUpdatedTitle() {
      const id = general.currentNode.id
      const currentNodeUpdatedTitle = general.nodeTitles[id] || ''
      return currentNodeUpdatedTitle;
    }

    // function getCurrentPreviewState() {
    //   return general.previewMode
    // }
    let updatedText = getCurrentNodeUpdatedText()
    let updatedImage = getNodeImageById()
    let updatedTitle = getCurrentNodeUpdatedTitle()
    let isHidden = general.previewMode
    let updatedList = elements.map(item => {
      if (item.id === general.currentNode.id) {
        return { ...item, data: { label: updatedTitle, image: updatedImage, details: updatedText, hideMore: isHidden } }; //gets everything that was already in item, and updates "done"
      }
      return item; // else return unmodified item
    });

    setElements(updatedList);
    setEditElements(general.editnodes);

  }, [general.nodeTexts, general.loadedFlow, general.nodeImages, general.nodeTitles, general.previewMode, general.showVideo, general.editnodes])

  const onConnect = (params) => {
    setElements((els) => addEdge(params, els));
  };

  const addNode = () => {
    const newNode = {
      id: getNodeId(),
      type: 'customnode',
      data: { label: '', image: '', details: '', hideMore: false },
      position: {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight,
      },
    };
    // let temp = Array.from(elements)
    // temp.push(newNode);
    // dispatch(setNodeAction(temp))
    setElements((els) => els.concat(newNode));

  }

  const addTextNode = () => {
    const newNode = {
      id: getNodeId(),
      type: 'customtextnode',
      data: { label: '', image: '', details: '', hideMore: false },
      position: {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight,
      },
    };
    setElements((els) => els.concat(newNode));
  }

  const addImageNode = () => {
    const newNode = {
      id: getNodeId(),
      type: 'customimagenode',
      data: { label: '', image: '', details: '', hideMore: false },
      position: {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight,
      },
    };
    setElements((els) => els.concat(newNode));
  }

  function undoAction() {
    let temp = Array.from(elements)
    let removed = temp.pop()
    setUndoStack(prevArray => [...prevArray, removed])
    setElements(temp)
  }

  function redoAction() {
    if (undoStack.length > 0) {
      let last = undoStack.pop()
      setElements(prevArray => [...prevArray, last])
    }
  }

  function setPreviewAction() {
    let flow = rfInstance.toObject();
    let arr = []
    let els = flow.elements.filter((x) => x.data !== undefined)
    let connections = flow.elements.filter((x) => x.data === undefined)
    arr = arr.concat(els)
    arr = arr.concat(connections)
    dispatch(setLoadedFlowAction(arr))
    dispatch(setPreviewModeAction(!general.previewMode))

    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  async function recordScreen() {
    let flow = rfInstance.toObject();
    let arr = []
    let els = flow.elements.filter((x) => x.data !== undefined)
    let connections = flow.elements.filter((x) => x.data === undefined)
    arr = arr.concat(els)
    arr = arr.concat(connections)
    dispatch(setLoadedFlowAction(arr))
    dispatch(showVideoAction(false))
    dispatch(setPreviewModeAction(true))
    dispatch(setRecordModeAction(true))
    setTimeout(() => {
      window.location.reload()
    }, 1000)
    // startRecording()
  }

  async function recordScreenNoVideo() {
    dispatch(setIsRecordAction(false))
    let flow = rfInstance.toObject();
    let arr = []
    let els = flow.elements.filter((x) => x.data !== undefined)
    let connections = flow.elements.filter((x) => x.data === undefined)
    arr = arr.concat(els)
    arr = arr.concat(connections)
    dispatch(setLoadedFlowAction(arr))
    dispatch(showVideoAction(false))
    dispatch(setPreviewModeAction(true))
    dispatch(setRecordModeAction(true))
    setTimeout(() => {
      window.location.reload()
    }, 1000)
    // startRecording()
  }

  function closeVideo() {
    // alert('closeddd')
    // mediaBlob = null;
    window.location.reload()
  }

  function stopRecord() {
    dispatch(setRecordModeAction(false))
    dispatch(setPreviewModeAction(false))
    stopRecording()
    dispatch(showVideoAction(true))
    message.info('Wait until video ends or skip to download video')
  }

  function deleteNode(node){
    let tempFlows = new Array(general.loadedFlow)
    let deleteNodeFlows = tempFlows[0].filter((item) => item.id !== node.id)
    // dispatch(setLoadedFlowAction(deleteNodeFlows))
    setElements(deleteNodeFlows)
  }

  const saveAction = useCallback(() => {
    if (rfInstance) {
      // const id = query.get('id').replace(' ', '_')
      let path = window.location.href
      let id = path.split('?')[1].split('=')[1].replace('%20', '_')
      if (id === null || id.length === 0 || id === undefined) {
        history.push('/')
      }
      let flow = rfInstance.toObject();
      let arr = []
      let els = flow.elements.filter((x) => x.data !== undefined)
      let connections = flow.elements.filter((x) => x.data === undefined)
      arr = arr.concat(els)
      arr = arr.concat(connections)
      let workflow = {
        elements: els,
        connections: connections,
        video: videoData ? videoData : null,
        updatedAt: new Date().toISOString()
      }
      dispatch(setLoadedFlowAction(arr))
      /* firebase.database().ref(`recommended/${id}`).set(workflow).then(() => {
        message.success('Workflow state successfully updated')
      }).catch((e) => {
        alert(e)
      }) */
      firebase.database().ref(`workflows/${firebase.auth().currentUser.uid}/${id}`).set(workflow).then(() => {
        message.success('Workflow state successfully updated new')
      }).catch((e) => {
        alert(e)
      })
    }
  }, [dispatch, history, query, rfInstance, videoData]);

  const onElementClick = (event, element) => {
    // dispatch(setRecordModeAction(false))
    dispatch(setCurrentNodeAction(element))
  }

  const CustomInput = () => (
    <div>
      <div>Only connectable with B</div>
      <Handle type="source" position="right" isValidConnection={isValidConnection} />
    </div>
  );

  const CustomNode = (node) => {
    const content = () => (
      <div className="flex flex-col w-auto">
        {
          node.data.image.length > 0 ? (
            <img className="my-2" alt="node_image" src={node.data.image} width={180} height={100} />
          ) : null
        }
        <HTMLRenderer
          html={node.data.details}
          components={{
            h1: props => <p {...props} />,
            p: props => <small {...props} />,
          }}
        />
        {
          general.previewMode === false ? (
            <div className={`flex flex-col ${node.data.details.length > 0 ? 'mt-5' : ''} mx-auto`}>
              {/* <Button style={{ marginRight: 10 }} onClick={() => deleteNode(node.id)}>Delete</Button> */}

              <Button onClick={() => {
                dispatch(openEditorAction(true))
              }}>Modify</Button>
            </div>
          ) : (<div />)
        }
      </div>
    )
    return (
      <Popover content={content} title="" trigger="click">
        <div key={node.id} className="flex flex-col p-4 bordered border-2">
          <p className="mx-auto my-auto"><b>{node.data.label}</b></p>
          <Handle type="target" position="left" isValidConnection={isValidConnection} />
          <Handle type="source" position="right" isValidConnection={isValidConnection} />
        </div>
      </Popover>

    )
  }

  const CustomImageNode = (node) => {
    const content = () => (
      <div className="flex flex-col w-auto">
        {
          general.previewMode === false ? (
            <div className="flex flex-row mt-2">
              {/* <Button style={{ marginRight: 10 }} onClick={() =>  deleteNode(node.id) }>Delete</Button> */}

              <Button style={{ marginRight: 10 }} onClick={() => {
                dispatch(openEditorAction(true))
              }}>Modify</Button>
              <Button onClick={() => {
                deleteNode(node)
              }}>Delete</Button>
            </div>
          ) : (<div />)
        }
      </div>
    )
    return (
      <Popover content={content} title="" trigger="click">
        <div key={node.id} className="flex flex-col bordered border-2">
          <img alt="node_image" style={{ height: 150, width: 150 }} src={node.data.image} />
          <Handle type="target" position="left" isValidConnection={isValidConnection} />
          <Handle type="source" position="right" isValidConnection={isValidConnection} />
        </div>
      </Popover>

    )
  }

  const CustomTextNode = (node) => {
    const content = () => (
      <div className="flex flex-col w-auto">
        {
          general.previewMode === false ? (
            <div className="flex flex-row mt-2">
              {/* <Button style={{ marginRight: 10 }} onClick={() => deleteNode(node.id)}>Delete</Button> */}

              <Button style={{ marginRight: 10 }} onClick={() => {
                dispatch(openEditorAction(true))
              }}>Modify</Button>
              <Button onClick={() => {
                deleteNode(node)
              }}>Delete</Button>
            </div>
          ) : (<div />)
        }
      </div>
    )
    return (
      <Popover content={content} title="" trigger="click">
        <div key={node.id} className="flex flex-col w-52 bordered border-2 p-2">
          <HTMLRenderer
            html={node.data.details}
            components={{
              h1: props => <p {...props} />,
              p: props => <small {...props} />,
            }}
          />
          <Handle type="target" position="left" isValidConnection={isValidConnection} />
          <Handle type="source" position="right" isValidConnection={isValidConnection} />

        </div>
      </Popover>

    )
  }

  const nodeTypes = {
    custominput: CustomInput,
    customnode: CustomNode,
    customtextnode: CustomTextNode,
    customimagenode: CustomImageNode
  };

  function Player({ srcBlob, audio }) {
    if (!srcBlob) {
      return null;
    }

    if (audio) {
      return <audio src={URL.createObjectURL(srcBlob)} controls />;
    }

    return (
      <VideoDisplay srcBlob={srcBlob} closeModal={closeVideo} />
    );
  }

  if (elements !== null) {
    return (
      <div className="flex flex-row h-screen w-full">
        <DrawerComp addBlock={addNode} addText={addTextNode} addImage={addImageNode} undoAction={undoAction} redoAction={redoAction} saveAction={saveAction} setPreview={setPreviewAction} startRecord={recordScreen}
          stopRecord={stopRecord} startRecordNoVideo={recordScreenNoVideo} />
        <ReactFlowProvider>
          <ReactFlow
            elements={elements}
            onElementsRemove={onElementsRemove}
            connectionMode="loose"
            onConnect={onConnect}
            selectNodesOnDrag={false}
            onElementClick={onElementClick}
            // onLoad={onLoad}
            onLoad={setRfInstance}
            className="validationflow"
            nodeTypes={nodeTypes}
            onConnectStart={onConnectStart}
            onConnectStop={onConnectStop}
            onConnectEnd={onConnectEnd}
          >
            <MiniMap
              nodeStrokeColor={(n) => {
                if (n.style?.background) return n.style.background;
                if (n.type === 'input') return '#0041d0';
                if (n.type === 'output') return '#ff0072';
                if (n.type === 'customnode') return '#1a192b';
                if (n.type === 'customtextnode') return '#1a192b';
                if (n.type === 'customimagenode') return '#1a192b';

                return '#eee';
              }}
              nodeColor={(n) => {
                if (n.style?.background) return n.style.background;

                return '#fff';
              }}
              nodeBorderRadius={2}
            />

            <Background color="black" gap={16} />
            <Controls />
          </ReactFlow>
        </ReactFlowProvider>
        <SideEditor />
        {
          mediaBlob && <div> <Player srcBlob={mediaBlob} /> </div>
        }
        {
          (status === 'recording' && general.isRecordVideo === true) &&
          <div style={{ position: 'absolute', bottom: 10, right: '40%' }} >
            <Webcam videoConstraints={videoConstraints} height={120}
              screenshotFormat="image/jpeg"
              width={180} />
          </div>
        }
      </div>
    );
  }

  return (
    <p className="text-center">Loading</p>
  )
};
