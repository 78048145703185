import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'general',
  initialState: {
    editnodes: [],
    openEditor: false,
    previewMode: false,
    recordScreen: false,
    showVideo: false,
    openCreateModal: false,
    openShareModal: false,
    currentNode: {},
    nodeTexts: {},
    nodeTitles: {},
    nodeImages: {},
    loadedFlow: {},
    subscribed: false,
    isRecordVideo: true
  },
  reducers: {
    toggleShowEditor: (state, action) => {
      state.openEditor = action.payload;
    },
    toggleisRecordVideo: (state, action) => {
      state.isRecordVideo = action.payload;
    },
    setSubscribed: (state, action) => {
      state.subscribed = action.payload
    },
    toggleShowVideo: (state, action) => {
      state.showVideo = action.payload;
    },
    togglepreviewMode: (state, action) => {
      state.previewMode = action.payload;
    },
    toggleRecordingMode: (state, action) => {
      state.recordScreen = action.payload;
    },
    toggleOpenCreateModal: (state, action) => {
      state.openCreateModal = action.payload;
    },
    toggleOpenShareModal: (state, action) => {
      state.openShareModal = action.payload;
    },
    setCurrentNode: (state, action) => {
      state.currentNode = action.payload;
    },
    setNodes: (state, action) => {
      state.editnodes = (action.payload)
    },
    setNodeText: (state, action) => {
      state.nodeTexts[state.currentNode.id] = action.payload;
    },
    setNodeImage: (state, action) => {
      state.nodeImages[state.currentNode.id] = action.payload;
    },
    setNodeTitles: (state, action) => {
      state.nodeTitles[state.currentNode.id] = action.payload;
      state.currentNode.data.title = action.payload;
    },
    setLoadedFlow: (state, action) => {
      // state.loadedFlow = []
      state.loadedFlow = action.payload;
    }
  },
});
export default slice.reducer
// Actions
const { toggleShowEditor, toggleOpenCreateModal, setCurrentNode, setNodeText, setNodeImage, setNodeTitles, setLoadedFlow, togglepreviewMode,
  toggleOpenShareModal, toggleRecordingMode, setNodes, toggleShowVideo, setSubscribed, toggleisRecordVideo } = slice.actions

export const openEditorAction = (value) => async dispatch => {
  try {
    dispatch(toggleShowEditor(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setIsRecordAction = (value) => async dispatch => {
  try {
    dispatch(toggleisRecordVideo(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setSubscribedAction = (value) => async dispatch => {
  try {
    dispatch(setSubscribed(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const showVideoAction = (value) => async dispatch => {
  try {
    dispatch(toggleShowVideo(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setNodeAction = (value) => async dispatch => {
  try {
    dispatch(setNodes(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const shareDocAction = (value) => async dispatch => {
  try {
    dispatch(toggleOpenShareModal(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setPreviewModeAction = (value) => async dispatch => {
  try {
    dispatch(togglepreviewMode(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setRecordModeAction = (value) => async dispatch => {
  try {
    dispatch(toggleRecordingMode(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const openCreateNewAction = (value) => async dispatch => {
  try {
    dispatch(toggleOpenCreateModal(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setLoadedFlowAction = (value) => async dispatch => {
  try {
    dispatch(setLoadedFlow(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setNodeImageAction = (value) => async dispatch => {
  try {
    dispatch(setNodeImage(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setNodeTitlesAction = (value) => async dispatch => {
  try {
    dispatch(setNodeTitles(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setNodeTextAction = (value) => async dispatch => {
  try {
    dispatch(setNodeText(value));
  } catch (e) {
    return console.error(e.message);
  }
}

export const setCurrentNodeAction = (value) => async dispatch => {
  try {
    dispatch(setCurrentNode(value));
  } catch (e) {
    return console.error(e.message);
  }
}