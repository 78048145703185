import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistReducer, persistStore, FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import user from './user'
import general from './general'

const persistConfig = {
  key: 'root',
  storage,
}
const reducer = combineReducers({
  // here we will be adding reducers
  user,
  general
})
const _persistedReducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer: _persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
});
export const persistor = persistStore(store);