import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import landingImage from '../images/landingImage.jpg';

function Header() {

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 h-20 ease-in-out ${!top && 'invisible'}`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6 mt-5">
        <div className="flex items-center justify-between h-10 md:h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4 mb-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Cruip">
            {/* <img alt="langing_image" className="w-20 h-16" src={landingImage} /> */}
            <span className='text-2xl font-bold'>MindMirror.co</span>

            </Link>
          </div>

          {/* Site navigation */}
          <nav className="flex flex-grow">
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link to="/account/login" className="flex flex-row btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                  <span className="font-bold">Get Started</span>
                  <svg className="mt-1 w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg>
                </Link>
              </li>
            </ul>

          </nav>

        </div>
      </div>
    </header>
  );
}

export default Header;
