import React from 'react';
import {
  Redirect
} from "react-router-dom";
import Lottie from 'react-lottie';
import animationData from '../lotties/9629-loading.json';

import { useAuthListener } from '../api/firebaseHooks';

const ProtectedRoute = ({ component: Component }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  // a custom hook to keep track of user's auth status
  const { loggedIn, checkingStatus } = useAuthListener();

  return (
    <>
      {
        // display a spinner while auth status being checked
        checkingStatus
          ? <div style={{ marginTop: window.innerHeight / 3 }}>
            <Lottie
              options={defaultOptions}
              height={200}
              width={200}
            />
          </div>
          : loggedIn
            // if user is logged in, grant the access to the route
            // note: in this example component is Bar
            ? <Component />
            // else render an unauthorised component
            // stating the reason why it cannot access the route
            : <Redirect to="/account/login" />
      }
    </>
  );
};

export default ProtectedRoute;