import React, { useState, useEffect } from 'react';
import firebase from "firebase/app";
import { useDispatch } from 'react-redux';
import { Card, Menu, Dropdown } from 'antd';
// import MiniBoard from '../components/MiniBoard';
import { setLoadedFlowAction, openCreateNewAction } from '../store/general';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    useHistory
} from "react-router-dom";
import { Skeleton, Button, Empty, message } from 'antd';
import "firebase/database";

export default function RecentDocuments() {
    const history = useHistory();
    const dispatch = useDispatch()
    const [documents, setDocuments] = useState([])
    const [loading, toggleLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleClick = () => {
        deleteDoc(currentItem)
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const getDocuments = () => {
            try {
                toggleLoading(true);
                firebase.database().ref(`workflows/${firebase.auth().currentUser.uid}`).on("value", (dataSnapShot) => {
                    let arr = []
                    dataSnapShot.forEach((child) => {
                        arr.push({
                            key: child.key,
                            elements: child.val().elements,
                            updatedAt: child.val().updatedAt,
                            connections: child.val().connections,
                            zoom: child.val().zoom
                        })
                    })
                    setDocuments(arr)
                    toggleLoading(false)
                })
            } catch (err) {
                toggleLoading(false);
            }
        }
        getDocuments()
    }, [])

    function openWorkFlow(flow) {
        let arr = []
        const elems = flow.elements || []
        const conns = flow.connections || []
        elems.forEach((fl) => {
            arr.push(fl)
        })
        conns.forEach((con) => {
            arr.push(con)
        })
        dispatch(setLoadedFlowAction(arr))
        history.push(`/editor/new?id=${flow.key.replace('_', ' ')}`);
    }

    function createNew() {
        dispatch(openCreateNewAction(true));
    }

    function deleteDoc(item) {
        firebase.database().ref(`workflows/${firebase.auth().currentUser.uid}/${item.key}`).remove().then(() => {
            message.success(`${item.key} deleted successfully`);
        }).then(() => {
            setIsModalOpen(false)
        })
    }

    const EmptyList = () => {
        return (
            <Empty
                description={
                    <span>
                        <h1 className="text-purple">You do not have any saved workflow</h1>
                    </span>
                }
            >
                <Button style={{ backgroundColor: '#549bd6', height: 38, fontWeight: 'bold' }} onClick={() => createNew()} type="primary">Create one Now</Button>
            </Empty>
        )
    }

    const Menuu = ({ item }) => {
        return (
            <Menu className="">
                <Menu.Item className="border border-2 border-purple" onClick={() => openWorkFlow(item)} key="1">Open Board</Menu.Item>
                <Menu.Item className="border border-2 border-purple" onClick={() => {
                    setCurrentItem(item);
                    showModal();
                }} key="2" danger>Delete</Menu.Item>
            </Menu>
        )
    }

    const MoreOptions = ({ item }) => {
        return (
            <div className="border border-purple">
                <Dropdown key="33" overlay={<Menuu item={item} />}>
                    <Button className="ant-dropdown-link" onClick={e => e.preventDefault()} key="2">...</Button>
                </Dropdown>
            </div>
        )
    }

    const ListItem2 = ({ item, keyy }) => {
        return (
            <div className='border border-2 rounded-md p-5 m-5'>
                <p><b>Title:</b> {item.key.replace('_', ' ')}</p>
                <p><b>Number of idea blocks:</b> {item.elements.length}</p>
                <small><b>Last update:</b> {new Date(item.updatedAt).toDateString()}</small>
            </div>
        )
    }

    const ListItem = ({ item, keyy }) => {
        let arr = []
        const elems = item.elements || []
        const conns = item.connections || []
        elems.forEach((fl) => {
            arr.push(fl)
        })
        conns.forEach((con) => {
            arr.push(con)
        })
        if (arr.length > 0) {
            return (
                <div key={keyy} className="m-5 border-purple border">
                    <Card style={{ padding: 2 }}
                        // title={<small>{item.key.replace('_', ' ')}</small>}
                        extra={<MoreOptions item={item} />} // style={{ width: 300, height: 150 }}
                    >
                        {
                            arr.length > 0 && <ListItem2 item={item} keyy={keyy} />
                        }
                    </Card>
                </div>
            )
        }
        return (
            <div key={item.key} className="m-5">
            </div>
        )
    }
    if (loading) {
        return <Skeleton active />
    }
    return (
        <div>
            <Dialog
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete project?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div class="w-96">
                            <p>This is an irreversible step. Click on delete to proceed.</p>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClick} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            {
                documents.length === 0 ? (
                   <div className='mt-24'>
                     <EmptyList />
                   </div>
                ) :
                    (
                        <div className="flex lg:flex-row md:flex-row sm:flex-col xs:flex-col lg:w-1/2 md:w-1/2 sm:mr-10">
                            {
                                documents.map((item) => <div key={item.key}><ListItem item={item} keyy={item.key} /></div>)
                            }
                        </div>
                    )
            }
        </div>
    )
}
