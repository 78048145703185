import React, { useState } from 'react';
import {
    useHistory
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";
// import TextField from '@material-ui/core/TextField';
import { Button, message, Card, Input } from 'antd';
// import { Space, Spin } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { GoogleOutlined } from '@ant-design/icons';
import Lottie from 'react-lottie';
import animationData from '../lotties/login.json';

export default function Login() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const history = useHistory();
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [loading, setLoading] = useState(false);

    function gotoLogin() {
        history.push('/account/login')
    }

    function performSignUp() {
        if (!email || !password) {
            message.warning('Please enter your email and/or password')
            return;
        }
        setLoading(true);
        firebase.auth().createUserWithEmailAndPassword(email, password).then(() => {
            firebase.database().ref(`users/${firebase.auth().currentUser.uid}`).set({
                expiry: new Date().toISOString(),
                email: email,
                type: 'email'
            }).then(() => {
                setLoading(false)
                history.push('/home')
            }).catch((err) => {
                setLoading(false)
                message.error(err.message)
            })
        }).catch((e) => {
            setLoading(false);
            message.error(JSON.stringify(e.message))
        })
    }

    function performGoogleLogin() {
        const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(googleAuthProvider).then((user) => {
            setLoading(true)
            if (user.additionalUserInfo.isNewUser === true) {
                const userInfo = user.additionalUserInfo.profile
                firebase.database().ref(`users/${firebase.auth().currentUser.uid}`).set({
                    name: userInfo.name,
                    picture: userInfo.picture,
                    email: userInfo.email,
                    type: 'google'
                }).then(() => {
                    setLoading(false)
                    history.push('/home')
                }).catch((err) => {
                    message.error(err.message)
                })
            } else {
                setLoading(false)
                history.push('/home')
            }
        }).catch((e) => {
            setLoading(false)
            message.error(JSON.stringify(e.message))
        })
    }

    const LoginForm = () => {
        return (
            <div className="flex flex-col">
                <Input placeholder="Enter email here"
                    id="login-email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    style={{ margin: 10, height: 40 }}
                />
                <Input.Password
                    placeholder="input password"
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ margin: 10, height: 40 }}
                    value={password}
                    id="login-password"
                />
                <div className="flex flex-col mx-5 mt-5 mb-4">
                    <Button disabled={loading} style={{ height: 40, width: 190, backgroundColor: '#549bd6', fontWeight: 'bold', borderRadius: 7, marginLeft: 'auto', marginRight: 'auto' }} onClick={() => performSignUp()} variant="contained" type="primary">
                        {!loading ? 'Register new account' : <span className="text-white" >Please wait...</span>}
                    </Button>
                </div>
                <p className="text-center grab mt-3">Already have an account? <span className="underline font-bold" onClick={() => gotoLogin()}>Login here</span> </p>
                <hr className="my-5" />
                <p className="text-center mt-2 font-bold">Or you can continue with</p>
                <div className="flex flex-col mt-2 justify-center">
                    <Button onClick={() => performGoogleLogin()} icon={<GoogleOutlined style={{ fontSize: 30 }} />} style={{ alignSelf: "center", width: '60%', height: '20%', margin: 10, backgroundColor: '#de5246', borderRadius: 7 }} variant="contained" type="primary">
                        Google
                    </Button>
                    {/* <Button onClick={() => performFacebookLogin()} icon={<FacebookOutlined style={{ fontSize: 30 }} />} style={{ alignSelf: "center", width: '60%', height: '20%', margin: 10, backgroundColor: '#4267B2' }} variant="contained" type="primary">
                        Facebook
                    </Button> */}
                </div>
            </div>
        )
    }
    return (
        <div className="flex lg:flex-row h-screen bg-purple">
            <div className="mx-auto mt-14 lg:block xs:hidden md:hidden">
                <h1 className="text-3xl text-white text-center font-bold">Mind Mirror</h1>
                <div className="mt-24">
                    <Lottie
                        options={defaultOptions}
                        height={400}
                        width={400}
                    />
                </div>
            </div>
            <div className="xs:mx-auto xs:my-auto sm:mx-auto sm:my-auto">
                <Card bordered={true} style={{ padding: 15, borderWidth: 3, marginTop: 5, borderRadius: 10 }}>
                    {LoginForm()}
                </Card>
            </div>
        </div>
    )
}