import React, { useState, useEffect } from 'react';
import { Drawer, Input, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { openEditorAction, setNodeImageAction, setNodeTextAction, setNodeTitlesAction } from '../store/general';
import { FileImageOutlined } from '@ant-design/icons';
import TextEditor from './TextEditor';
// import firebase from "firebase/app";
import "firebase/storage";

export default function SideEditor() {
    // console.log("ww", window.location.href)
    // let path = window.location.href
    // let id = path.split('?')[1].split('=')[1]
    // console.log("ww2", id)
    // const query = new URLSearchParams(window.location.search);
    // const id = query.get('id').replace(' ', '_')
    // const inputFile = useRef(null)
    const dispatch = useDispatch()
    const general = useSelector((state) => state.general)
    // const [image, setImage] = useState({ preview: general.currentNode?.data?.image || '', raw: general.currentNode?.data?.image || '' });
    const [image, setImage] = useState({});
    // console.log("ww2", general.currentNode)
    const [loading, toggleLoading] = useState(false);
    const [, setImageUrl] = useState(general.currentNode?.data?.image || '');
    const [title, setTitle] = useState(general.currentNode?.data?.label || '')
    const [, setDetails] = useState(null);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }

    const handleChange = e => {
        if (e.target.files.length) {
            console.log('filee', e.target.files[0])
            getBase64(e.target.files[0]).then(
                data => {
                    console.log('ddd', data)
                    dispatch(setNodeImageAction(data))
                    toggleLoading(false)
                }
            );
            /* var storage = firebase.storage();
            var uploadTask = storage.ref(`images/${id}/${general.currentNode.id}.png`).put(e.target.files[0])
            uploadTask.on('state_changed',
                (snapShot) => {
                    // console.log(snapShot)
                }, (err) => {
                    toggleLoading(false)
                    // console.log(err)
                }, () => {
                    storage.ref('images').child(`${id}/${general.currentNode.id}.png`).getDownloadURL()
                        .then(fireBaseUrl => {
                            dispatch(setNodeImageAction(fireBaseUrl))
                            setImage({
                                preview: fireBaseUrl,
                                raw: e.target.files[0]
                            });
                        }).catch(() => {
                            toggleLoading(false)
                        })
                }) */
        }
        toggleLoading(false)
    };


    useEffect(() => {
        function getNodeImageById() {
            const id = general.currentNode.id
            const currentNodeUpdatedImage = general.nodeImages[id] || ''
            return currentNodeUpdatedImage;
        }

        function getCurrentNodeUpdatedText() {
            const id = general.currentNode.id
            const currentNodeUpdatedText = general.nodeTexts[id] || ''
            return currentNodeUpdatedText;
        }

        function getCurrentNodeUpdatedTitle() {
            const id = general.currentNode.id
            const currentNodeUpdatedTitle = general.nodeTitles[id] || ''
            return currentNodeUpdatedTitle;
        }
        let updatedText = getCurrentNodeUpdatedText()
        let updatedImage = getNodeImageById()
        let updatedTitle = getCurrentNodeUpdatedTitle()
        setImage(updatedImage)
        setTitle(updatedTitle)
        setDetails(updatedText)

    }, [general?.currentNode?.id, general.nodeImages, general.nodeTexts, general.nodeTitles, general.openEditor])




    const onClose = () => {
        setImageUrl(null)
        dispatch(openEditorAction(false))
    };

    function onChangeText(text) {
        dispatch(setNodeTextAction(text))
    }

    function onChangeTitle(title) {
        dispatch(setNodeTitlesAction(title))
    }

    function inputClick() {
        if(!general.subscribed){
            message.info('You cannot use this feature with your current plan')
            return
        }
        let aa = document.getElementById('upload-button')
        aa.click()
    }
    return (
        <div>
            <Drawer
                width={347}
                title={<span className="">Edit Node</span>}
                placement={"right"}
                closable={true}
                onClose={onClose}
                visible={general.openEditor}
                key={"right"}
            >
                {(general.currentNode.type === 'customnode' || general.currentNode.type === 'customtextnode') &&
                    <>
                        {
                            general.currentNode.type === 'customnode' &&
                            <div className="my-5">
                                <span>Enter node header</span>
                                <Input value={title} onChange={(e) => {
                                    onChangeTitle(e.target.value)
                                    setTitle(e.target.value)
                                }} placeholder="Node heading here" />
                            </div>
                        }
                        <span>Enter details here</span>
                        <TextEditor text={general.currentNode?.data?.details} updateTextState={onChangeText} />
                    </>
                }
                {(general.currentNode.type === 'customnode' || general.currentNode.type === 'customimagenode') &&
                    <div className="mt-2">
                        <div>
                            <label htmlFor="upload-button">
                                {image ? (
                                    <div>
                                        <img className="mx-auto" src={image} alt="dummy" style={{ width: 200, height: 180 }} />
                                        <div className="mt-10 ml-20">
                                            <Button icon={<FileImageOutlined style={{ fontSize: 20 }} />} onClick={() => inputClick()} type="primary" loading={loading}>
                                                <span>{!loading ? 'Change Image' : 'Loading'}</span>
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {/* <span className="fa-stack fa-2x mt-3 mb-2">
                                            <i className="fas fa-circle fa-stack-2x" />
                                            <i className="fas fa-store fa-stack-1x fa-inverse" />
                                        </span> */}
                                        <div className="flex flex-col mx-auto">
                                            <Button icon={<FileImageOutlined style={{ fontSize: 20 }} />} style={{ height: 38, fontWeight: 'bold' }} onClick={() => inputClick()} type="primary" loading={loading}>
                                                <span>{!loading ? 'Upload Image' : 'Loading'}</span>
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                id="upload-button"
                                style={{ display: "none" }}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                }
            </Drawer>
        </div>
    )
}