import React from "react";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { HashRouter } from "react-router-dom";
import "./App.css";
import NewEditor from "./pages/neweditor";
import Editor from "./pages/Editor";
import Login from "./pages/Login";
// import Landing from './pages/Landing';
import SignUp from "./pages/SignUp";
import Home from "./pages/Home";
import Settings from "./pages/Settings";
import UserProvider from "./providers/UserProvider";
import ResetPassword from "./pages/ResetPassword";
import Upgrade from "./pages/Upgrade";
import NewLanding from "./pages/NewLanding";
import Dubem from "./pages/Dubem";

import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <UserProvider>
      <HashRouter>
        <div>
          <Switch>
            <ProtectedRoute path="/editor/new" component={NewEditor} />
            <ProtectedRoute path="/user/settings" component={Settings} />
            <ProtectedRoute path="/user/upgrade" component={Upgrade} />
            <ProtectedRoute exact path="/home" component={Home} />
            <ProtectedRoute exact path="/editor/main" component={Editor} />
            <Route path="/account/login">
              <Login />
            </Route>
            <Route path="/lonewolf">
              <Dubem />
            </Route>
            <Route path="/newlanding">
              <NewLanding />
            </Route>
            <Route path="/account/reset">
              <ResetPassword />
            </Route>
            <Route path="/account/new">
              <SignUp />
            </Route>
            <Route path="/">
              <NewLanding />
            </Route>
          </Switch>
        </div>
      </HashRouter>
    </UserProvider>
  );
}

export default App;
